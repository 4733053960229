import React, { useState, useEffect } from 'react';
import { Calendar, Clock, MapPin, Plus, Edit2, Trash2, Users, Menu } from 'lucide-react';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "./ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { Button } from "./ui/buttons";
import { Input } from "./ui/inputs";
import { Textarea } from "./ui/textarea";
import { Label } from "./ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/react-select";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { API_BASE_URL } from './config';

const EventDashboard = () => {
  const [events, setEvents] = useState([]);
  const [view, setView] = useState('upcoming');
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    date: '',
    time: '',
    location: '',
    image: null,
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [view]);

  const fetchEvents = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/events?view=${view}`);
      const data = await response.json();
      setEvents(data);
    } catch (error) {
      console.error('Failed to fetch events:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = selectedEvent 
      ? `${API_BASE_URL}/api/events/${selectedEvent._id}`
      : `${API_BASE_URL}/api/events`;

    const method = selectedEvent ? 'PUT' : 'POST';

    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      if (formData[key]) {
        formDataToSend.append(key, formData[key]);
      }
    });

    try {
      const response = await fetch(url, {
        method,
        body: formDataToSend,
      });

      if (response.ok) {
        setIsAddDialogOpen(false);
        setSelectedEvent(null);
        setFormData({
          title: '',
          description: '',
          date: '',
          time: '',
          location: '',
          image: null,
        });
        fetchEvents();
      }
    } catch (error) {
      console.error('Failed to save event:', error);
    }
  };

  const handleEdit = (event) => {
    setSelectedEvent(event);
    setFormData({
      title: event.title,
      description: event.description || '',
      date: new Date(event.date).toISOString().split('T')[0],
      time: event.time,
      location: event.location,
      image: null,
    });
    setIsAddDialogOpen(true);
  };

  const handleDelete = async (eventId) => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/events/${eventId}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          fetchEvents();
        }
      } catch (error) {
        console.error('Failed to delete event:', error);
      }
    }
  };

  const formatDate = (dateString) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      ...(isMobileView ? {} : {
        hour: '2-digit',
        minute: '2-digit'
      })
    }).format(new Date(dateString));
  };

  const EventCard = ({ event }) => (
    <Card className="flex flex-col h-full">
      {event.image && (
        <div className="relative w-full pt-[56.25%]">
          <img
            src={`${API_BASE_URL}${event.image}`}
            alt={event.title}
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
        </div>
      )}
      <CardHeader className="flex-none">
        <CardTitle className="text-lg md:text-xl line-clamp-2">{event.title}</CardTitle>
        <CardDescription className="line-clamp-2">{event.description}</CardDescription>
      </CardHeader>
      <CardContent className="flex-grow">
        <div className="space-y-2">
          <div className="flex items-center text-sm">
            <Calendar className="w-4 h-4 mr-2 flex-shrink-0" />
            <span className="truncate">{formatDate(event.date)}</span>
          </div>
          <div className="flex items-center text-sm">
            <Clock className="w-4 h-4 mr-2 flex-shrink-0" />
            <span className="truncate">{event.time}</span>
          </div>
          <div className="flex items-center text-sm">
            <MapPin className="w-4 h-4 mr-2 flex-shrink-0" />
            <span className="truncate">{event.location}</span>
          </div>
          {event.registrations && (
            <div className="flex items-center text-sm">
              <Users className="w-4 h-4 mr-2 flex-shrink-0" />
              <span>{event.registrations} registrations</span>
            </div>
          )}
        </div>
      </CardContent>
      <CardFooter className="flex-none">
        {isMobileView ? (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="w-full">
                <Menu className="w-4 h-4 mr-2" />
                Actions
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent 
            align="end" 
            className="w-48 sm:w-56 bg-white rounded-md shadow-lg p-2 md:p-4 space-y-2"
          >
            <DropdownMenuItem 
              onClick={() => handleEdit(event)} 
              className="text-black-600 flex items-center hover:bg-gray-100 px-10 py-2 rounded-md"
            >
              <Edit2 className="w-4 h-4 mr-2" />
              Edit Event
            </DropdownMenuItem>
            <DropdownMenuItem 
              onClick={() => handleDelete(event._id)} 
              className="text-red-600 flex items-center hover:bg-gray-100 px-10 py-2 rounded-md"
            >
              <Trash2 className="w-4 h-4 mr-2" />
              Delete Event
            </DropdownMenuItem>
          </DropdownMenuContent>

          </DropdownMenu>
        ) : (
          <div className="flex gap-2 w-full">
            <Button className="flex-1" onClick={() => handleEdit(event)}>
              <Edit2 className="w-4 h-4 mr-1" /> Edit
            </Button>
            <Button variant="danger" className="flex-1" onClick={() => handleDelete(event._id)}>
              <Trash2 className="w-4 h-4 mr-1" /> Delete
            </Button>
          </div>
        )}
      </CardFooter>
    </Card>
  );

  return (
    <div className="p-4 md:p-6 max-w-7xl mx-auto">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-6">
        <div>
          <h1 className="text-2xl md:text-3xl font-bold">Event Dashboard</h1>
          <p className="text-gray-500">Manage your events</p>
        </div>
        <div className="flex flex-col sm:flex-row w-full md:w-auto gap-4">
          <Select value={view} onValueChange={setView}>
            <SelectTrigger className="w-full sm:w-32">
              <SelectValue placeholder="View" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="upcoming">Upcoming</SelectItem>
              <SelectItem value="past">Past</SelectItem>
            </SelectContent>
          </Select>
          <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
            <DialogTrigger asChild>
              <Button className="w-full sm:w-auto">
                <Plus className="w-4 h-4 mr-2" />
                Add Event
              </Button>
            </DialogTrigger>
            <DialogContent className="w-[95vw] md:max-w-xl mx-auto">
              <DialogHeader>
                <DialogTitle>
                  {selectedEvent ? 'Edit Event' : 'Add New Event'}
                </DialogTitle>
              </DialogHeader>
              <div className="max-h-[80vh] overflow-y-auto px-4 pb-4">
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="title">Title</Label>
                  <Input
                    id="title"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="description">Description</Label>
                  <Textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    rows={3}
                  />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="date">Date</Label>
                    <Input
                      id="date"
                      name="date"
                      type="date"
                      value={formData.date}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="time">Time</Label>
                    <Input
                      id="time"
                      name="time"
                      type="time"
                      value={formData.time}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="space-y-2">
                  <Label htmlFor="location">Location</Label>
                  <Input
                    id="location"
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="image">Image</Label>
                  <Input
                    id="image"
                    name="image"
                    type="file"
                    accept="image/*"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex flex-col sm:flex-row justify-end gap-2">
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => setIsAddDialogOpen(false)}
                    className="w-full sm:w-auto"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className="w-full sm:w-auto">
                    {selectedEvent ? 'Update Event' : 'Create Event'}
                  </Button>
                </div>
              </form>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
        {events.map((event) => (
          <EventCard key={event._id} event={event} />
        ))}
        {events.length === 0 && (
          <div className="col-span-full text-center py-8 text-gray-500">
            No events found
          </div>
        )}
      </div>
    </div>
  );
};

export default EventDashboard;