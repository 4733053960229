import React, { useState, useEffect } from 'react';
import { 
  Card, 
  CardHeader, 
  CardTitle, 
  CardContent 
} from './ui/card';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from './ui/table';
import { Button } from './ui/buttons';
import { Input } from './ui/inputs';
import { Checkbox } from './ui/checkbox';
import { 
  Alert,
  AlertDescription
} from './ui/alert';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { Trash2, Plus, Search, Loader2, Menu, Mail, Calendar, CheckCircle } from 'lucide-react';
import { API_BASE_URL } from './config';

const StatusBadge = ({ status }) => (
  <span className="inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
    <CheckCircle className="h-3 w-3" />
    {status}
  </span>
);

const SubscriberDashboard = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newEmail, setNewEmail] = useState('');
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    try {
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'N/A';
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        ...(isMobileView ? {} : {
          hour: '2-digit',
          minute: '2-digit'
        })
      }).format(date);
    } catch (error) {
      return 'N/A';
    }
  };

  // Fetch subscribers
  const fetchSubscribers = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/subscribers`);
      if (!response.ok) throw new Error('Failed to fetch subscribers');
      const data = await response.json();
      setSubscribers(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscribers();
  }, []);

  const handleAddSubscriber = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/api/subscribe`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: newEmail, agreedToTerms }),
      });
      
      if (!response.ok) throw new Error('Failed to add subscriber');
      
      await fetchSubscribers();
      setNewEmail('');
      setAgreedToTerms(false);
      setShowAddDialog(false);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDeleteSubscriber = async (email) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/unsubscribe`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      
      if (!response.ok) throw new Error('Failed to delete subscriber');
      
      await fetchSubscribers();
    } catch (err) {
      setError(err.message);
    }
  };

  const filteredSubscribers = subscribers.filter(sub => 
    sub.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const MobileSubscriberCard = ({ subscriber }) => (
    <div className="bg-white p-4 rounded-lg shadow-sm border mb-4">
      <div className="flex justify-between items-start mb-2">
        <div className="break-all flex gap-2 items-center justify-center">
        <Mail className="w-4 h-4" />
          {subscriber.email}</div>
        <Button
          variant="ghost"
          size="sm"
          onClick={() => handleDeleteSubscriber(subscriber.email)}
          className="ml-2"
        >
          <Trash2 className="h-4 w-4" />
        </Button>
      </div>
      
      <div className="text-sm text-gray-500 mb-2 flex gap-2 items-center">
      <Calendar className="w-4 h-4" />
     
        {formatDate(subscriber.subscribedAt)}
      </div>
      <StatusBadge status="Active" />
    </div>
  );

  return (
    <div className="p-2 md:p-4 max-w-6xl mx-auto space-y-4">
      <Card>
        <CardHeader className="flex flex-col md:flex-row items-start md:items-center justify-between space-y-4 md:space-y-0 pb-4">
          <CardTitle className="text-xl md:text-2xl">Subscriber Management</CardTitle>
          <Dialog open={showAddDialog} onOpenChange={setShowAddDialog}>
            <DialogTrigger asChild>
              <Button className="w-full md:w-auto flex items-center justify-center space-x-2">
                <Plus className="h-4 w-4" />
                <span>Add Subscriber</span>
              </Button>
            </DialogTrigger>
            <DialogContent className="w-[95vw] md:w-full max-w-md mx-auto">
              <DialogHeader>
                <DialogTitle>Add New Subscriber</DialogTitle>
              </DialogHeader>
              <form onSubmit={handleAddSubscriber} className="space-y-4">
                <Input
                  type="email"
                  placeholder="Email address"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  required
                />
                <div className="flex items-center space-x-2">
                  <Checkbox
                    id="terms"
                    checked={agreedToTerms}
                    onCheckedChange={setAgreedToTerms}
                  />
                  <label htmlFor="terms" className="text-sm">
                    Agreed to terms and conditions
                  </label>
                </div>
                <Button type="submit" className="w-full">
                  Add Subscriber
                </Button>
              </form>
            </DialogContent>
          </Dialog>
        </CardHeader>
        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          
          <div className="mb-4">
            <div className="relative">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search subscribers..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="pl-8"
              />
            </div>
          </div>

          {loading ? (
            <div className="flex justify-center items-center py-8">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <>
              {isMobileView ? (
                <div className="space-y-2">
                  {filteredSubscribers.map((subscriber) => (
                    <MobileSubscriberCard 
                      key={subscriber.email} 
                      subscriber={subscriber} 
                    />
                  ))}
                </div>
              ) : (
                <div className="overflow-x-auto">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Email</TableHead>
                        <TableHead>Subscription Date</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead className="text-right">Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {filteredSubscribers.map((subscriber) => (
                        <TableRow key={subscriber.email}>
                          <TableCell className="max-w-xs truncate">
                            {subscriber.email}
                          </TableCell>
                          <TableCell>{formatDate(subscriber.subscribedAt)}</TableCell>
                          <TableCell>
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              Active
                            </span>
                          </TableCell>
                          <TableCell className="text-right">
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => handleDeleteSubscriber(subscriber.email)}
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )}
            </>
          )}
          
          {!loading && filteredSubscribers.length === 0 && (
            <div className="text-center py-8 text-muted-foreground">
              No subscribers found
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default SubscriberDashboard;