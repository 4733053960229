import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Button } from './ui/buttons';
import { Input } from './ui/inputs';
import { Select } from './ui/select';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from './ui/table';
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle, 
  DialogTrigger 
} from './ui/react-dialog';
import { Edit, Trash2, Plus } from 'lucide-react';
import { API_BASE_URL } from './config';

const ScheduleDashboard = () => {
  const [schedules, setSchedules] = useState([]);
  const [services, setServices] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    date: '',
    day: '',
    start_time: '',
    end_time: '',
    timezone: 'UTC+8',
    service: '',
    instructor: ''
  });
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    fetchSchedules();
    fetchServices();
    fetchInstructors();
  }, []);

  const fetchSchedules = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/schedules`);
      const data = await response.json();
      setSchedules(data);
    } catch (error) {
      console.error('Error fetching schedules:', error);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/services`);
      const data = await response.json();
      setServices(data);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  const fetchInstructors = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/instructors`);
      const data = await response.json();
      setInstructors(data);
    } catch (error) {
      console.error('Error fetching instructors:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = editingId 
        ? `${API_BASE_URL}/api/schedules/${editingId}`
        : `${API_BASE_URL}/api/schedules`;
      
      const method = editingId ? 'PUT' : 'POST';
      
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        fetchSchedules();
        setIsDialogOpen(false);
        resetForm();
      }
    } catch (error) {
      console.error('Error saving schedule:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this schedule?')) {
      try {
        await fetch(`${API_BASE_URL}/api/schedules/${id}`, {
          method: 'DELETE',
        });
        fetchSchedules();
      } catch (error) {
        console.error('Error deleting schedule:', error);
      }
    }
  };

  const handleEdit = (schedule) => {
    setEditingId(schedule._id);
    setFormData({
      date: schedule.date.split('T')[0],
      day: schedule.day,
      start_time: schedule.start_time,
      end_time: schedule.end_time,
      timezone: schedule.timezone,
      service: schedule.service._id,
      instructor: schedule.instructor._id
    });
    setIsDialogOpen(true);
  };

  const resetForm = () => {
    setFormData({
      date: '',
      day: '',
      start_time: '',
      end_time: '',
      timezone: 'UTC+8',
      service: '',
      instructor: ''
    });
    setEditingId(null);
  };

  return (
<Card className="w-full">
  <CardHeader className="flex flex-row items-center justify-between">
    <CardTitle>Schedule</CardTitle>
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger asChild>
        <Button
          onClick={() => {
            resetForm();
            setIsDialogOpen(true);
          }}
          className="flex items-center gap-2"
        >
          <Plus className="w-4 h-4" />
          Add Schedule
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-screen overflow-y-auto sm:max-w-md">
        <DialogHeader>
          <DialogTitle>{editingId ? 'Edit Schedule' : 'Add New Schedule'}</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="space-y-2">
              <label>Date</label>
              <Input
                type="date"
                value={formData.date}
                onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                required
              />
            </div>
            <div className="space-y-2">
              <label>Day</label>
              <Select
                value={formData.day}
                onChange={(e) => setFormData({ ...formData, day: e.target.value })}
                required
              >
                <option value="">Select day</option>
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => (
                  <option key={day} value={day}>{day}</option>
                ))}
              </Select>
            </div>
            <div className="space-y-2">
              <label>Start Time</label>
              <Input
                type="time"
                value={formData.start_time}
                onChange={(e) => setFormData({ ...formData, start_time: e.target.value })}
                required
              />
            </div>
            <div className="space-y-2">
              <label>End Time</label>
              <Input
                type="time"
                value={formData.end_time}
                onChange={(e) => setFormData({ ...formData, end_time: e.target.value })}
                required
              />
            </div>
            <div className="space-y-2">
              <label>Service</label>
              <Select
                value={formData.service}
                onChange={(e) => setFormData({ ...formData, service: e.target.value })}
                required
              >
                <option value="">Select service</option>
                {services.map(service => (
                  <option key={service._id} value={service._id}>
                    {service.service_name}
                  </option>
                ))}
              </Select>
            </div>
            <div className="space-y-2">
              <label>Instructor</label>
              <Select
                value={formData.instructor}
                onChange={(e) => setFormData({ ...formData, instructor: e.target.value })}
                required
              >
                <option value="">Select instructor</option>
                {instructors.map(instructor => (
                  <option key={instructor._id} value={instructor._id}>
                    {instructor.name}
                  </option>
                ))}
              </Select>
            </div>
          </div>
          <Button type="submit" className="w-full">
            {editingId ? 'Update Schedule' : 'Create Schedule'}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  </CardHeader>
  <CardContent>
    <div className="rounded-md border overflow-x-auto">
      <Table className="w-full">
        <TableHeader>
          <TableRow>
            <TableHead>Date</TableHead>
            <TableHead>Day</TableHead>
            <TableHead>Time</TableHead>
            <TableHead>Service</TableHead>
            <TableHead>Instructor</TableHead>
            <TableHead className="text-right">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {schedules.map((schedule) => (
            <TableRow key={schedule._id}>
              <TableCell>
                {new Date(schedule.date).toLocaleDateString()}
              </TableCell>
              <TableCell>{schedule.day}</TableCell>
              <TableCell>
                {schedule.start_time} - {schedule.end_time}
              </TableCell>
              <TableCell>{schedule.service.service_name}</TableCell>
              <TableCell>{schedule.instructor.name}</TableCell>
              <TableCell className="text-right">
                <div className="flex justify-end gap-2">
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={() => handleEdit(schedule)}
                  >
                    <Edit className="w-4 h-4" />
                  </Button>
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={() => handleDelete(schedule._id)}
                  >
                    <Trash2 className="w-4 h-4" />
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  </CardContent>
</Card>

  );
};

export default ScheduleDashboard;